//
// the MDM grid
//

var mdmgrid = {

        problemsAddressed: {
        label: "Problems",
        sections: {

            problems: {
            label: "Problems",
            choices: {
                oneMinor: {
                label: "1 minor",
                type: "one",
                value: 2,
                state: 0,
                },
                twoMinor: {
                label: "2 or more minor",
                type: "one",
                value: 3,
                state: 0,
                },
            },
            }, // problems

            newProblems: {
            label: "New Problems",
            choices: {
                newMinor: {
                label: "Undiagnosed problem with an uncertain prognosis",
                type: "one",
                value: 4,
                state: 0,
                },
            },
            }, // new problems



            acute: {
            label: "Acute Illness",
            choices: {
                uncomplicatedIllness: {
                label: "Uncomplicated",
                type: "one",
                value: 3,
                state: 0,
                },
                systemic: {
                label: "Systemic symptoms",
                type: "one",
                value: 4,
                state: 0,
                },
                threatIllness: {
                label: "Posing a threat to life or bodily function",
                type: "one",
                value: 5,
                state: 0,
                },
            },
            }, // acute

            chronic: {
            label: "Chronic Illness",
            choices: {
                stable: {
                label: "1 Stable",
                type: "one",
                value: 3,
                state: 0,
                },
                stables: {
                label: "2 or more Stable",
                type: "one",
                value: 4,
                state: 0,
                },
                progressing: {
                label: "Exacerbated, progressing, etc.",
                type: "one",
                value: 4,
                state: 0,
                },
                severe: {
                label: "Severe",
                type: "one",
                value: 5,
                state: 0,
                },
            },
            }, // chronic

            injury: {
            label: "Acute Injury",
            choices: {
                uncomplicatedInjury: {
                label: "Uncomplicated",
                type: "one",
                value: 3,
                state: 0,
                },
                complicatedInjury: {
                label: "Complicated",
                type: "one",
                value: 4,
                state: 0,
                },
                threatInjury: {
                label: "Posing a threat to life or bodily function",
                type: "one",
                value: 5,
                state: 0,
                },
            },
            }, // injury

        },  // closing grid.problemsAddressed.sections

        },  // closing grid.problemsAddressed

        dataReviewed: {
        label: "Data",
        sections: {

            actions: {
            label: "Actions taken",
            choices: {
                sources: {
                label: "Unique sources reviewed",
                type: "number",
                level: 3,
                value: 0,
                state: 0,
                },
                ordered: {
                label: "Tests ordered",
                type: "number",
                level: 3,
                value: 0,
                state: 0,
                },
                reviewed: {
                label: "Tests reviewed",
                type: "number",
                level: 3,
                value: 0,
                state: 0,
                },
            },
            }, // actions

            externalInput: {
            label: "External input",
            choices: {

                historian: {
                label: "Assessment by an independent historian",
                type: "many",
                value: 3,
                state: 0,
                },
                independent: {
                label: "Independent interpretation of test results",
                type: "many",
                value: 4,
                state: 0,
                },
                discussion: {
                label: "Discussion of management or treatment",
                type: "many",
                value: 4,
                state: 0,
                },
            },
            }, // externalInput

        },  // closing grid.dataReviewed.sections

        }, // closing grid.dataReviewed

        levelOfRisk: {
        label: "Risk",
        sections: {
            risk: { 
            label: "Risk",

            choices: {

                minimal: {
                label: "Minimal",
                type: "one",
                value: 2,
                state: 0,
                },
                low: {
                label: "Low",
                type: "one",
                value: 3,
                state: 0,
                },
                moderate: {
                label: "Moderate",
                type: "one",
                value: 4,
                state: 0,
                },
                high: {
                label: "High",
                type: "one",
                value: 5,
                state: 0,
                },
            },
            }, // risk

        }, // closing grid.sections.risk

        }, // closing grid.risk

    }; // closing grid

export default mdmgrid