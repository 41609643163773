<template>
  <div id="app">
    <ul class='flex cursor-pointer sticky'>
      <li :class="[isActive == 'mdm' ? `${activeCss} mdm active` : 'mdm', defaultCss]" @click="isActive='mdm'">MDM Tool</li>
      <li :class="[isActive == 'emtimer' ? `${activeCss} em-timer active` : 'em-timer', defaultCss]" @click="isActive='emtimer'">E&M Timer</li>
  
    </ul>
    <MDM v-if="isActive == 'mdm'" />
    <EMTimer v-if="isActive == 'emtimer'" />
  </div>
</template>
<script>
import MDM from './components/MDM.vue'
import EMTimer from './components/EMTimer.vue'

export default {
  name: 'App',
  components: {
    MDM,
    EMTimer,
  },
  data() {
    return {
      isActive: 'mdm',
      activeCss: 'text-black font-bold',
      defaultCss: 'py-2 px-6'
    }
  },
  // created() {
    // if (localStorage.getItem('isActive') !== 'null') {
    //   this.isActive = localStorage.getItem('isActive');
    // }
  // },
  mounted() {},
  watch: {
    // isActive(n, o) {
    //   localStorage.setItem("isActive", n);
    // }
  }
}

</script>
<style lang="scss">


.mdm {
  background-color: hsl(30, 70%, 10%);
  color: gray;
  &.active {
    background-color: hsl(30, 80%, 50%);
    color: white;
  }
}
.em-timer {
  background-color: hsl(212, 71%, 11%);
  color: #ababab;
  &.active {
    background-color: hsl(212, 71%, 41%);
    color: white;
  }
}

</style>
