<template>
  <span>
    <div :class="{'flex flex-wrap': controls == 'true'}">
      <div :class="{'w-1/2': controls == 'true'}">
        <span v-if="clabel">{{ clabel }}</span>
        <input v-on:keyup.enter="parseEdit(editedTime)" v-on:blur="parseEdit(editedTime)" type="text" inputmode="number" v-model="editedTime" v-show="editing" :class="['bg-yellow-100', 'clock']" ref="timeeditor" @focus="$event.target.select()" v-on:keydown="restrict($event)" />
        <input :class="[{'text-center w-20 border p-1 m-2': true, 'border-white': disabled === true}, 'clock']" @click="doEdit()" type="text" v-show="!editing" :value="displayTime()" @focus="switchFocus()" ref="timedisplay" />
      </div>
      <div class="w-1/2 text-center mt-2" v-if="controls=='true'">
        <input @click="ticking == false ? start() : stop()" :class="[buttonClass, {'text-red-500 bg-white': ticking == true}, {'bg-green-200': ticking == false}]" type="button" :value="ticking ? 'Stop' : 'Start'" id="sw-go" />
        <input @click="now=0;stop();update()" :class="[buttonClass, 'text-gray-500']" type="button" value="Clr" id="sw-rst" />
      </div>
    </div>
  </span>
</template>
<script>
import { bus } from '../main';
import TimeFunctions from '../TimeFunctions'

export default {
    name: 'Clock',
    props: ['value', 'action', 'controls', 'timein', 'clabel', 'disabled'],
    data() {
        return {
            now: 0, //seconds
            inaction: 'null',
            ticking: false,
            editing: false,
            editedTime: '',
            timer: {},
            buttonClass: 'rounded border-none bg-gray-200 text-xs p-2 ml-1 cursor-pointer',
            clockClass: 'text-center border w-20 p-1 m-2'
        }
    },
    mounted() {
        this.now = (this.timein ? parseInt(this.timein) : 0);
        this.update();
        this.inaction = this.action;
        bus.$on('stop', this.stop)
    },
    watch: {
        timein: {
            deep: true,
            handler(n, o) {
                this.now = n;
                this.update();
            }
        },
        action: {
            deep: true,
            handler(n, o) {
                this.inaction = this.action;
            }
        },
        inaction: {
            deep: true,
            handler(n, o) {
                this.reset();
            }
        },
        editing(n, o) {
            if (n === true) {
                // this.$refs.timeeditor.$el.focus();
                this.$nextTick(() => {
                    // this.$refs.timeeditor.focus();
                    this.$refs.timeeditor.select()
                })

            }
        }

    },
    methods: {
        update() {
            this.$emit('updatetime', { 'time': this.now });
        },
        parseEdit(t) {
            t = t.replace(/[^0-9:]/g, '');
            if (Number.isNaN(t)) {
                t = 0;
            }
            this.stop();
            this.editing = false;
            // this.editedTime = this.now;
            let parts = this.editedTime.split(':');
            parts[0] = (isNaN(parseFloat(parts[0])) ? 0 : parseInt(parts[0]));
            if (parts[1]) {
                this.now = (parseInt(parseFloat(parts[0])) * 60) + parseInt(parseFloat(parts[1]));
            } else {
                this.now = Math.round(parseInt(t) * 60);
            }

            this.update();
        },
        tick() {
            // (B1) CALCULATE HOURS, MINS, SECONDS
            this.now++;
            this.update();
        },
        displayTime() {
            return TimeFunctions.displayTime(this.now);

        },
        start() {

            bus.$emit('stop');
            this.editing = false;
            this.$nextTick(() => {
                this.timer = setInterval(this.tick, 1000);
                this.ticking = true;
            });
            // sw.ego.removeEventListener("click", sw.start);
            // sw.ego.addEventListener("click", sw.stop);
        },
        stop() {
            clearInterval(this.timer);
            this.ticking = false;
            // this.timer = null;
        },
        reset() {
            this.now = 0;
            this.stop();
            this.update();
            this.inaction = 'null';
        },
        switchFocus() {
            let vm = this;
            if (this.disabled === true) {
                vm.$refs.timedisplay.blur();
                return false;
            }
            vm.$refs.timedisplay.blur();
            this.editing = true;
            vm.$nextTick(() => {
                vm.$refs.timeeditor.focus()
            })
        },
        doEdit() {
            if (this.disabled === true) {
                this.editing = false;
                return false;
            } else {
                this.stop();
                this.editedTime = displayTime();
                this.editing = true;
            }
        },
        restrict(t) {
            let editKeys = ['Tab', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Enter', 'Delete'];

            if (/[0-9:]/.test(t.key) || editKeys.indexOf(t.key) > -1) {
                return true;
            } else {
                t.preventDefault();
                return false;
            }
        }

    }
}

</script>
