<template>
  <div class="mb-2">
    <header id="status" class="emtimer-tool bg-gray-100 rounded sticky top-0">
      <div class="grid grid-cols-4 w-full mt-2">
        <div class="col-span-1 text-sm text-center">Total Time: <div class=" w-full text-center text-3xl font-bold">{{ displayTime() }}</div>
        </div>
        <div class="col-span-3 text-right pr-2">
          <p class="text-xl">New: <span class="font-bold" v-if="newCode.level">{{ newCode.code }} / Level {{ newCode.level }}</span></p>
          <p class="text-xl">Est: <span class="font-bold" v-if="establishedCode.level">{{ establishedCode.code }} / Level {{ establishedCode.level }}</span></p>
        </div>
        <div class="col-span-4 text-right">
          <button @click="reset" class="reset">reset</button>
        </div>
      </div>
    </header>
    <table class="w-full table-fixed">
      <tbody class="text-left">
        <template v-for="(section, label) in sections">
          <tr>
            <td colspan=2>
              <h1 class="font-bold text-md text-center p-0">
                <totaler :clabel="prettyLabel(label)" :numarray="counters[label]" @updatetime="time => { counters[label][0] = time }" />
              </h1>
            </td>
          </tr>
          <tr class="text-xs text-gray-400">
            <th class="pl-1">Activities</th>
            <th class="pl-2">Time Spent<p class="text-xs">(entered as mins or mins:secs)</p></th>
          </tr>
          <template v-for="(s,index) in section">
            <tr class="bg-gray-100 section">
              <td class="select-none p-1 text-xs">{{ s }}</td>
              <td class="">
                <Clock timein="0" controls="true" :action="clockAction" @updatetime="time => { addCounter(label, index, time) }" />
              </td>
              <!-- @updatetime="time => { updateTime(time) }" -->
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import Clock from '@/components/Clock'
import Totaler from '@/components/Totaler'
import TimeFunctions from '../TimeFunctions'
import { bus } from '../main';

export default {
    name: 'EmTimer',
    components: { Clock, Totaler },
    computed: {
        establishedCode() {
            let t = this.total / 60;

            if (t < 10) {
                return { 'code': '00000', 'level': '0' };
            }
            if (t >= 10 && t <= 19) {
                return { 'code': 99212, 'level': 2 };
            }
            if (t >= 20 && t <= 29) {
                return { 'code': 99213, 'level': 3 };
            }
            if (t >= 30 && t <= 39) {
                return { 'code': 99214, 'level': 4 };
            }
            if (t >= 40 && t <= 54) {
                return { 'code': 99215, 'level': 5 };
            }
            if (t > 54) {
                return { 'code': '00000', 'level': '5+' };
            }
            return {'code': '00000', 'level': '?'};
        },
        newCode() {
            let t = this.total / 60;
            if (t < 15) {
                return { 'code': '00000', 'level': '0' };
            }
            if (t >= 15 && t <= 29) {
                return { 'code': 99202, 'level': 2 };
            }
            if (t >= 30 && t <= 44) {
                return { 'code': 99203, 'level': 3 };
            }
            if (t >= 45 && t <= 59) {
                return { 'code': 99204, 'level': 4 };
            }
            if (t >= 60 && t <= 74) {
                return { 'code': 99205, 'level': 5 };
            }
            if (t > 74) {
                return { 'code': '00000', 'level': '5+' };
            }
            return {'code': '00000', 'level': '?'};
        }
    },
    data() {
        return {

            clockAction: 'null',
            total: 0,
            indexes: 0,
            previsitcounters: [],
            counters: { 'previsit': [], 'visit': [], 'postvisit': [] },
            sections: {
                'previsit': [
                    'Reviewing notes, results, correspondence, reports (Note sources, dates)',
                    'Other'
                ],
                'visit': [
                    'History from patient and others (note sources)',
                    'History, examination, discussion, counseling, education, planning',
                    'Ordering, referrals, documenting',
                    'Planning, ordering, referrals',
                    'Other'
                ],
                'postvisit': [
                    'Documenting',
                    'Reviewing and communicating results',
                    'Independent interpretation of tests (not separately billed)',
                    'Care coordination (not separately billed)',
                    'Other'
                ]
            }
        }
    },
    mounted() {
    },
    methods: {
        displayTime() {
            return TimeFunctions.displayTime(this.total);
        },
        addCounter(label, index, time) {
            this.$set(this.counters[label], index, time.time)
            this.sumAllCounters();
        },
        sumAllCounters() {
            this.total = 0;
            for (let c in this.counters) {
              this.total += this.sum(this.counters[c])
            }
        },
        sum(arr) {
             return arr.reduce(function(r, a) {
                return r + a;
            }, 0);
        },
        onTicked(v) {
            let count = 0;
            for (let x in v) {
                count += v.time;
            }
            this.total = count;
        },
        prettyLabel(l) {
            switch (l) {
                case 'previsit':
                    return 'Pre-Visit';
                    break;
                case 'visit':
                    return 'Visit';
                    break;
                case 'postvisit':
                    return 'Post-Visit';
                    break;
                default:
                    return '';
                    break;
            }
        },
        reset() {
            let vm = this;
            vm.clockAction = 'reset';
            setTimeout(function() { vm.clockAction = 'null' }, 500);
        }

    }
    // props: {
    //   msg: String
    // }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

tr.section {
  border-bottom: 1px solid rgba(0,0,0,.1);
  &:last-child {
    border-bottom: none;
  }
}
</style>
