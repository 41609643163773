export default {
  displayTime(total) {
    var remain = total;
    var hours = Math.floor(remain / 3600);
    remain -= hours * 3600;
    var mins = Math.floor(remain / 60);
    remain -= mins * 60;
    var secs = remain;

    if (hours < 10) { hours = "0" + hours; }
    if (mins < 10) { mins = "0" + mins; }
    if (secs < 10) { secs = "0" + secs; }
    return (hours > 0 ? `${hours}:${mins}:${secs}` : `${mins}:${secs}`);
  },

}
