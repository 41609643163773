<template>
  <span>
    <Clock :disabled="true" :clabel="clabel" :timein="total" controls="false" @updatetime="time => { doAdd(time) }" />
  </span>
</template>
<script>
import Clock from '@/components/Clock'
export default {
    components: { Clock },
    props: ['numarray', 'clabel'],
    data() {
        return {
            numbers: [],
            total: 0
        }
    },
    watch: {
        numarray(n, o) {
            this.numbers = n;
        },
        numbers: {
            deep: true,
            immediate: true,
            handler(n, o) {
                this.doAdd();
            }
        }
    },
    mounted() {
        this.numbers = this.numarray;
    },
    methods: {
        doAdd() {
            this.total = this.numbers.reduce(function(r, a) {

                return r + parseInt(a);

            }, 0);
        },
        doTime(time) {
            this.numbers = [];
            this.numbers[0] = time;
        }
    }
}

</script>
