<template>
  <div>
    <header id="status" class="mdm-tool w-full stuck heat-0 pb-4 z-20">
      <div id="results" class="w-1/2">
        <div class="details grid grid-cols-2 gap-2 text-left mt-2 mb-4">
          <div class="col-span-1">Problems:</div>
          <div> {{ problemScore }}</div>
          <div>Data:</div>
          <div> {{ dataScore }}</div>
          <div>Risk:</div>
          <div> {{ riskScore }}</div>
        </div>
      </div>
      <div class="mt-1 text-right text-sm w-1/2 text-gray-700">
        Recommended Coding
        <div :class="`font-bold heat-${finalScore} text-4xl`">Level {{ finalScore }}
        </div>
      </div>
      <div class="absolute bottom-0 right-0 mr-2">
        <button @click="reset()" class="reset">reset</button>
      </div>
    </header>
    <div id="main">
      <template v-for="g, section in mgrid">
        <a :name="`section-${section}`"></a>
        <div class="text-xl relative z-0 text-center mt-2 pl-2 bg-white cursor-pointer select-none font-bold" @click="openPanel = (openPanel == section ? 'null' : section);scrollToSection(section)"><span class="mdm-closer">{{ (openPanel == section ? '-' : '+') }}</span>{{ g.label }}<span class="font-bold float-right pr-2">{{ showScoreInline(section) }}</span></div>
        <section :class="{'mb-4': true, 'hide': openPanel != section, 'show': openPanel == section}">
          <template v-for="s,subCat in g.sections">
            <div class="subcat" v-if="subCat != 'actions'">
              <div class="text-md text-gray-700 font-bold">{{ s.label }}</div>
              <template v-for="c,choiceIndex in s.choices">
                <button :class="buttonClass(c.value, c.state)" @click="selectChoice(section, subCat, choiceIndex, c.state, c.type)"> {{ c.label }}</button>
              </template>
            </div>
            <div class="bg-gray-200 my-2 rounded p-2" v-if="subCat == 'actions'">
              <div class="text-xl">{{ s.label }}</div>
              <template v-for="c,choiceIndex in s.choices">
                <!-- <div class="numbers bg-blue-100 rounded"> -->
                <button :class="doButtonClass(c.value)" @click="(c.value < c.level ? c.value += 1 : c.value = 0);doScore()">{{ c.label }}: <span class="font-bold text-xl">{{ c.value }}<span class="pl-1" v-if="c.value==3">+</span></span></button>
                <!-- </div> -->
              </template>
            </div>
          </template>
        </section>
      </template>
    </div>
    <p class="text-center">
    <button @click="reset()" class="w-28 mt-8 p-2 ml-2 rounded-lg bg-red-100 border-gray-300">reset</button>
    </p>
    <div class="p-2 terms text-xs text-left m-2">
      <h3 class="text-left text-sm">Copyright and Terms of Use</h3>
      <p>
        This application uses visual feedback to help
        clinicians understand how to select the appropriate
        billing code using Medical Decision Making per the 2021
        guidelines recommended by CMS. It is being provided to
        the general public for free. Use it at your own risk.
        All of the information and logic used is set forth in
        this HTML file and associated JavaScript and CSS files,
        and is already stored in your browser (use View Page Source
        to review the source code).
      </p>
      <p>
        This application is a stand-alone application. It does not
        reqiuire an interet connection to run. It does not track any
        usage statistics in any way shape or form.
        This application does not rely on any third-party JavaScript
        platforms or frameworks. It uses your browser's default
        fonts and does not download any fonts or images.
      </p>
      <p>
        All of the source code necessary to run this application
        is in this one single HTML file, which is now in your browser.
        You are welcome to download, store, and run
        <a href="index.html">this file</a> on your own computer.
      </p>
      <p>
        This application may be shared with others and its
        contents freely reproduced (and even incorporated in
        commecial products), provided that no fees (or
        additional fees) are are charged. If you really
        appreciate this application, please make a donation to
        your local food bank.
      </p>
      </p>
      If you have any questions or suggestions, please send me
      an email message to <a href="mailto:decisionmaking2021@gmail.com">
        decisionmaking2021@gmail.com</a>.
      If you decide to write your own
      Medical Decision Making coding tool, please let me know and I
      will gladly promote it for you.
      <p>
        <p>
          This website and its contents are Copyright 2021 by the author. {{ width }}px. 
        </p>
    </div>
  </div>
</template>
<script>
import mdmgrid from '../grid.js'

export default {
    name: 'MDM',
    data() {
        return {
            openPanel: 'problemsAddressed',
            mgrid: null,
            problemScore: 0,
            riskScore: 0,
            dataScore: 0,
            finalScore: 0,
            ts: new Date(),
            groupNames: [],
            width:  document.body.clientWidth 
        }
    },
    mounted() {
        this.init();
        this.doScore();
    },
    methods: {
        init() {
            var ts = new Date();
            var i, p, o, b, title;
            var div, divAction, divNumber, rv, fs, panel, sec, choice;
            var groupNames = [];
            this.mgrid = mdmgrid;

            //
            // Set things up
            //

            //
            // Add HTML h1 element for the project title
            //
            title = document.getElementById("title");

            //
            // Step through the grid and add HTML elements
            //
            //     Each property/entry in grid represents a panel/section.
            //     Each panel will be contained inside its own div.
            //

        },
        selectChoice(section, cat, i, current, ctype = 'one') {

            // s.choices.each((e) => e.state = 0)
            // (c.state == 1 ? c.state = 0 : c.state=1)
            // this.mgrid[section][cat][i].state=1;
            if (ctype == 'one') {
                for (var choice in this.mgrid[section].sections[cat].choices) {
                    this.mgrid[section].sections[cat].choices[choice].state = 0;
                }
            }

            this.mgrid[section].sections[cat].choices[i].state = (current == 1 ? 0 : 1);
            this.doScore();
        },
        buttonClass(vl, st) {
            return `w-full p-2 rounded my-1 ${st == 1 ? 'on' : 'off'}${vl}`
        },
        reset() {
            location.href = "?";
        },
        doScore() {
            this.scoreProblemsAddressed();
            this.scoreDataReviewed();
            this.scoreRisk();
            this.finalScore = [this.problemScore, this.dataScore, this.riskScore].sort()[1];
        },
        scoreProblemsAddressed() {
            this.problemScore = 0;
            let last = 0;
            for (var i in this.mgrid.problemsAddressed.sections) {
                var sec = this.mgrid.problemsAddressed.sections[i];
                
                for (var id in sec.choices) {
                    
                    if (sec.choices[id].state == 1) {
                        console.log(last, sec.choices[id].value);
                        this.problemScore = (sec.choices[id].value >= last ? sec.choices[id].value : last);
                        last = sec.choices[id].value;
                    }
                }
            }
        },
        scoreDataReviewed() {
            let choices = this.mgrid.dataReviewed.sections.externalInput.choices;
            let reviewActions = this.mgrid.dataReviewed.sections.actions.choices;
            let groupA = reviewActions.sources.value + reviewActions.ordered.value + reviewActions.reviewed.value;
            let groupAA = groupA;
            let catA = 0;

            if (this.mgrid.dataReviewed.sections.externalInput.choices.historian.state == 1) {
                groupAA++;
                catA = 1;
            }

            let cat1 = 0;
            if (groupAA >= 3) {
                cat1 = 1;
            }
            let cat2 = 0;
            if (this.mgrid.dataReviewed.sections.externalInput.choices.independent.state == 1) {
                cat2 = 1;
            }
            let cat3 = 0;
            if (this.mgrid.dataReviewed.sections.externalInput.choices.discussion.state == 1) {
                cat3 = 1;
            }
            let cats = cat1 + cat2 + cat3;

            let rv = 2;
            if (cats >= 2) {
                rv = 5;
            } else if (cats == 1) {
                rv = 4;
            } else if (groupA >= 2 || catA == 1) {
                rv = 3;
            }
            this.dataScore = rv;
        },
        scoreRisk() {
            let riskScore = 0;

            for (let i in this.mgrid.levelOfRisk.sections) {
                let sec = this.mgrid.levelOfRisk.sections[i];
                for (let id in sec.choices) {
                    if (sec.choices[id].state == 1) {
                        if (sec.choices[id].value > riskScore) {
                            this.riskScore = sec.choices[id].value;
                        }
                    }
                }
            }
        },
        doButtonClass(v) {
            let bg = 'off3';
            switch (v) {
                case 1:
                    bg = 'on3';
                    break;
                case 2:
                case 3:
                    bg = 'on4';
                    break;
                default:
                    // bg = 'off3';
                    break;
            }
            return `w-full ${bg} rounded px-2 mt-1`
        },
        showScoreInline(section) {
            switch (section) {
                case 'problemsAddressed':
                    return this.problemScore;
                    break;
                case 'dataReviewed':
                    return this.dataScore;
                    break;
                case 'levelOfRisk':
                    return this.riskScore;
                    break;
                default:
                    return section;
                    return;
                    break;
            }
            return section;
        },
        scrollToSection(s) {
            // window.location.hash = `#section-${s}`
            window.scrollTo(0, 0);

        }
    }
}
// props: {
//   msg: String
// }

</script>
<style lang="scss">
section {
  transition: max-height .2s ease-in-out;
  overflow: hidden;
  max-height: 0;

  &.show {
    max-height: 1200px;
  }

}

</style>
